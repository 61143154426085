@import "base";

html, body { background:$global-muted-background; }

.el-fg-white { color:#fff; }

.el-bg-primary { background:lighten(mix(white, $global-primary-background, 30%), 30%); }
.el-bg-success { background:lighten(mix(white, $global-success-background, 20%), 25%); }
.el-bg-warning { background:lighten(mix(white, $global-warning-background, 30%), 15%); }
.el-bg-danger { background:lighten(mix(white, $global-danger-background, 30%), 15%); }

.el-controlCell { padding-top:12px !important; padding-bottom:11px !important; }
.el-errorCell { padding-top:12px !important; padding-bottom:11px !important; }

.el-emptyRow { border:0 !important;
  th, td { padding:0 !important; border:0 !important; }
}

.ly-header {
  .ly-header-logo {
    a { display:inline; padding:0; font-size:1.25rem; font-weight:bold; white-space:nowrap; text-transform:none; }
  }
  .ly-header-main { background:$global-header-background; color:$global-header-foreground; padding-left:20px;
    a { color:inherit; }
    .uk-navbar-dropdown { padding:5px 0;
      @media (max-width: $breakpoint-small-max) {
        left:auto !important; right:0;
      }
    }
    .uk-navbar-dropdown-nav > li > a { padding:10px 15px;
      &:hover { background:$global-muted-background; }
    }
    @media (min-width: $breakpoint-medium) {
      padding:0 20px;
    }
  }
  .ly-header-sub { background:$global-navigation-background; color:$global-navigation-foreground; padding:5px 10px;
    a { color:inherit; padding:5px 10px; min-height:0 !important; margin-right:0.5rem;
      &.active { background:$global-header-background; }
    }
    li:hover > a { color:transparentize($global-navigation-foreground, 0.2); }
  }
  .ly-header-notice {
    background:$global-navigation-notice-background;
    color:$global-navigation-notice-foreground;
    padding:5px 10px;
    text-align: center;

    > ul {
      display:inline-block;
      text-align: left;
    }
  }
}

.bl-pageHeader { margin:2rem 0;
  .bl-pageHeader-title { font-size:1.25rem;
    @media (min-width: $breakpoint-medium) {
      font-size:1.75rem;
    }
  }
  .uk-tab::before { display:none; }
  .uk-search-input, .uk-search-input:focus { background:#fff; }
  @media (max-width: $breakpoint-small-max) {
    .uk-button { font-size:0.875rem; padding:0 15px; }
  }
}

.ly-main { padding-bottom:3rem; }

.bl-pageFooter { margin:2rem 0; }

.bl-videoList { font-size:0.8rem;
  @media (min-width: $breakpoint-medium) {
    font-size:1rem;
  }
  .bl-videoList-row:hover { background:$table-row-active-background;
    .bl-videoList-item-command { opacity:1; }
  }
  .bl-videoList-item {
    .bl-videoList-item-inner { display:flex; align-items:flex-start; }
    .bl-videoList-item-thumbnail { position:relative; width:150px; flex-grow:0;
      @media (min-width: $breakpoint-small) {
        width:200px;
      }
      .bl-videoList-item-thumbnail-img { padding-bottom:56.25%; background:center center #666; background-size:cover; }
      .bl-videoList-item-thumbnail-time { position:absolute; right:0; bottom:0; font-size:0.875rem; color:#fff; background:#000; padding:3px 5px; }
    }
    .bl-videoList-item-info { margin-left:1rem; }
    .bl-videoList-item-title { display:block; font-weight:bold; margin-bottom:0.5em;
      .uk-label { margin-left:0.5em; font-size:0.8em; }
    }
    .bl-videoList-item-description { margin-bottom:0.5em; }
    .bl-videoList-item-command { font-size:0.8rem; list-style:none; margin:0; padding:0; opacity:1;
      @media (min-width: $breakpoint-medium) {
        font-size:0.875rem; opacity: 0; transition:0.3s;
      }
      .uk-button-link { display:inline; font-size:inherit; line-height:inherit; vertical-align:baseline; }
      a, .uk-button-link { color:#666; transition:opacity 0.2s;
        &:hover { text-decoration:none; opacity:0.6; }
      }
      > li { display:inline; margin-right:0.5em;
        &::after { content:""; border-right:1px solid #ccc; margin-left:0.5em; }
        &:last-child::after { display:none; }
      }
    }
  }
}

.bl-videoUpload { position:fixed; z-index:$notification-z-index - 10; left:0; top:0; width:100%; height:100%; background:$modal-background;
  &-wrap { }
  .bl-videoUpload-box { position:absolute; $offset:30px; left:$offset; right:$offset; top:$offset; bottom:$offset; }
  .bl-videoUpload-dropzone { cursor:pointer; position:absolute; left:0; top:0; width:100%; height:100%; box-sizing:border-box; padding:20px; transition:background-color 0.2s;
    .bl-videoUpload-dropzone-main { display:flex; justify-content:center; align-items:center; width:100%; height:100%; text-align:center; box-sizing:border-box; border:4px dashed #ccc; pointer-events:none; transition:border-color 0.2s; }
    &.is-active { background:#ccc;
      .bl-videoUpload-dropzone-main { border-color:#999; }
    }
  }
  .bl-videoUpload-close { position:absolute; right:10px; top:10px; }
}

.bl-videoPlayer { position:relative; padding-bottom:56.25%;
  .bl-videoPlayer-video { position:absolute; left:0; top:0; width:100%; height:100%; }
  .bl-videoPlayer-tooltip { $color:#fff; $triangle-width:10px; $triangle-height:10px; position:absolute; white-space:nowrap; left:0; bottom:100%; margin-bottom:10px; font-size:14px; padding:10px 20px; background:#fff; color:#333; border-radius:4px;
    &::after { content:''; position:absolute; left:25px; top:100%; pointer-events:none; border:$triangle-width solid transparent; border-left-width:calc($triangle-width / 2); border-right-width:calc($triangle-width / 2); border-top-color:#fff; margin-left:-$triangle-width; }
  }
  .vjs-has-started.vjs-ended .vjs-big-play-button { display:block; }
}

.bl-errorMessages { font-size:$global-small-font-size; color:$text-danger-color; padding-top: $form-horizontal-label-margin-top;
  .bl-errorMessages-text { margin-bottom:0.5em; }
}

.bl-modal {
  .bl-modal-control {
    .uk-button { padding:0 20px; }
    .uk-button + .uk-button { margin-left:0.5em; }
  }
}
