$global-header-background: #A6032F;
$global-header-foreground: #fff;
$global-navigation-background: #D9145C;
$global-navigation-foreground: #fff;
$global-navigation-notice-background: #e7b8dd;
$global-navigation-notice-foreground: #D9145C;
$navbar-nav-item-hover-color: transparentize(#fff, 0.3);
$global-muted-background: lighten(#FAEDFF, 2);
$global-primary-background: #D9145C;
$global-link-color: darken($global-navigation-background, 2);
$global-link-hover-color: darken($global-navigation-background, 2);
$table-row-active-background: darken($global-muted-background, 1);

$form-width-xsmall: 80px;
$button-font-size: 1rem;
$button-large-font-size: 1rem;
$navbar-nav-item-height: 60px;
$navbar-dropdown-margin: 0;
$navbar-dropdown-padding: 0;
$heading-medium-font-size-l: 2rem;
$notification-message-background: #fff;
$notification-message-font-size: 1rem;
$modal-title-font-size: 1.4rem;

$heading-small-font-size-m: 1.4rem;

@import "../../node_modules/uikit/src/scss/variables-theme.scss";
@import "../../node_modules/uikit/src/scss/mixins-theme.scss";

@mixin hook-notification-message() {
  box-shadow: $global-medium-box-shadow;
}

@mixin hook-margin-misc() {

  /* Phone landscape and bigger */
  @media (min-width: $breakpoint-small) {
    .uk-margin-small-top\@s { margin-top: $margin-small-margin !important; }
    .uk-margin-small-bottom\@s { margin-bottom: $margin-small-margin !important; }
    .uk-margin-top\@s { margin-top: $margin-margin !important; }
    .uk-margin-bottom\@s { margin-top: $margin-margin !important; }
    .uk-margin-remove-top\@s { margin-top: 0 !important; }
    .uk-margin-remove-bottom\@s { margin-top: 0 !important; }
  }

  /* Tablet landscape and bigger */
  @media (min-width: $breakpoint-medium) {
    .uk-margin-small-top\@m { margin-top: $margin-small-margin !important; }
    .uk-margin-small-bottom\@m { margin-bottom: $margin-small-margin !important; }
    .uk-margin-top\@m { margin-top: $margin-margin !important; }
    .uk-margin-bottom\@m { margin-top: $margin-margin !important; }
    .uk-margin-remove-top\@m { margin-top: 0 !important; }
    .uk-margin-remove-bottom\@m { margin-top: 0 !important; }
  }

  /* Desktop and bigger */
  @media (min-width: $breakpoint-large) {
    .uk-margin-small-top\@l { margin-top: $margin-small-margin !important; }
    .uk-margin-small-bottom\@l { margin-bottom: $margin-small-margin !important; }
    .uk-margin-top\@l { margin-top: $margin-margin !important; }
    .uk-margin-bottom\@l { margin-top: $margin-margin !important; }
    .uk-margin-remove-top\@l { margin-top: 0 !important; }
    .uk-margin-remove-bottom\@l { margin-top: 0 !important; }
  }

  /* Large screen and bigger */
  @media (min-width: $breakpoint-xlarge) {
    .uk-margin-small-top\@xl { margin-top: $margin-small-margin !important; }
    .uk-margin-small-bottom\@xl { margin-bottom: $margin-small-margin !important; }
    .uk-margin-top\@xl { margin-top: $margin-margin !important; }
    .uk-margin-bottom\@xl { margin-top: $margin-margin !important; }
    .uk-margin-remove-top\@xl { margin-top: 0 !important; }
    .uk-margin-remove-bottom\@xl { margin-top: 0 !important; }
  }
}

@mixin hook-form-misc() {
  .uk-checkbox.is-indeterminate { @extend .uk-checkbox, :indeterminate; }
  .uk-checkbox.is-checked { @extend .uk-checkbox, :checked; }
}
